export * from './config';

// General
export const DEFAULT_TITLE = 'GoFan - The largest high school ticketing solution in the U.S.';

// Images
export const DEFAULT_SCHOOL_LOGO = 'gofan-icon-big.png';

// Search
export const DEFAULT_MAX_SEARCH_DISTANCE = 25.3;
export const DEFAULT_DISTANCE_UNIT = 'mile';

// Tabs
export const UPCOMING_TAB_TEXT = 'Upcoming Events';
export const PAST_TAB_TEXT = 'Past Events';

// Date
export const DEFAULT_FORMAT = 'YYYY-MM-DDTHH:mm:ssZZ';
export const YEAR_FORMAT = 'YYYY';
export const MONTH_DAY_FORMAT = 'MMM DD';
export const MONTH_DAY_YEAR_FORMAT = 'MMM DD, YYYY';
export const SHORT_MONTH_DAY_YEAR_FORMAT = 'MMDDYY';
export const FULL_DATE_TIME_FORMAT = 'ddd, MMM DD, YYYY [at] h:mm A';
export const MONTH_DAY_YEAR_TIME_FORMAT = 'MMM DD, YYYY [at] h:mm A';
export const FULL_MONTH_DAY_YEAR_TIME_FORMAT = 'MMMM DD, YYYY [at] h:mm A';
export const DATE_TIME_FORMAT = 'ddd, MMM DD, h:mm A';
export const DATE_FORMAT = 'ddd, MMM DD';
export const FULL_DATE_FORMAT = 'ddd, MMM DD, YYYY';
export const FULL_DAY_IN_WEEK_FORMAT = 'dddd, MMM DD, YYYY';
export const DEFAULT_TIMEZONE = 'UTC';
export const DATE_TIME_UTC_FORMAT = 'YYYY-MM-DDTHH:mm:ss.000UTC';
export const ORDER_CREATE_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const MINUTES = (minutes: number) => minutes * 60 * 1000;
export const DEFAULT_HOLDING_CART_TIME = MINUTES(15);
export const DEFAULT_HOLDING_SEAT_TIME = MINUTES(15);
export const DEFAULT_HOLDING_ACCESS_CODE_TIME = MINUTES(10);
export const TIME_TO_DELAY_PRINT_PDF = 5 * 1000;
export const MAX_PRINT_PDF_RETRY = 3;
export const DEFAULT_HOLDING_PROMO_CODE_TIME = MINUTES(9);

// Events
export const MAX_CAPACITY_REMINDER = 10;

export const SHORTENED_DESCRIPTOR = 'GOFAN';
export const DESCRIPTOR_PREFIX_LENGTH = `${SHORTENED_DESCRIPTOR}* `.length;
export const DESCRIPTOR_MAX_LENGTH = 22;
export const DEFAULT_STATEMENT_DESCRIPTOR = `GoFan Tickets`;

// Navigation

type PageConfig = {
  [n: string]: {
    id: string;
    name: string;
    icon: string;
    path: string;
    pageTitle: string;
    hideMobileFooter?: boolean;
    hideCookiesBanner?: boolean;
    getPath: Function;
  };
};
export const GOFAN_APP_PAGES: PageConfig = {
  home: {
    id: 'home',
    name: 'Home',
    icon: 'Home',
    path: '/',
    getPath: () => '/',
    pageTitle: DEFAULT_TITLE
  },
  search: {
    id: 'search',
    name: 'Search',
    icon: 'search',
    path: '/',
    getPath: () => '/',
    pageTitle: DEFAULT_TITLE
  },
  reviewAndBuy: {
    id: 'reviewAndBuy',
    name: 'Review and buy',
    icon: 'reviewAndBuy',
    path: '/review-and-buy',
    getPath: () => '/review-and-buy',
    pageTitle: 'Review and buy',
    hideMobileFooter: true
  },
  school: {
    id: 'schools',
    name: 'Schools',
    icon: 'schools',
    path: '/app/school/:id',
    getPath: (schoolId = '', queryString = '') => `/app/school/${schoolId}${queryString}`,
    pageTitle: "School's Events and Tickets by GoFan"
  },
  shortName: {
    id: 'shortName',
    name: 'Schools',
    icon: 'schools',
    path: '/:id',
    getPath: (shortName = '', queryString = '') => `/${shortName}${queryString}`,
    pageTitle: "School's Events and Tickets by GoFan"
  },
  mySchool: {
    id: 'mySchools',
    name: 'My Schools',
    icon: 'schools',
    path: '/my-schools',
    getPath: () => '/my-schools',
    pageTitle: 'My Schools'
  },
  'terms-of-use': {
    id: 'terms-of-use',
    name: 'Terms of Use',
    icon: 'terms-of-use',
    path: '/terms-of-use',
    getPath: () => '/terms-of-use',
    pageTitle: 'GoFan.co Terms and Conditions of Use',
    hideCookiesBanner: true
  },
  smsTerms: {
    id: 'smsTerms',
    name: 'SMS Terms of Service',
    icon: 'smsTerms',
    path: '/sms-terms',
    getPath: () => '/sms-terms',
    pageTitle: 'GoFan.co SMS Terms of Use',
    hideCookiesBanner: true
  },
  'privacy-policy': {
    id: 'privacy-policy',
    name: 'Privacy Policy',
    icon: 'privacy-policy',
    path: '/privacy-policy',
    getPath: () => '/privacy-policy',
    pageTitle: 'GoFan.co Privacy Policy',
    hideCookiesBanner: true
  },
  signIn: {
    id: 'signIn',
    name: 'Sign In',
    icon: 'signIn',
    path: '/sign-in',
    getPath: () => '/sign-in',
    pageTitle: 'GoFan Sign In'
  },
  signUp: {
    id: 'signUp',
    name: 'Sign Up',
    icon: 'signUp',
    path: '/sign-up',
    getPath: () => '/sign-up',
    pageTitle: 'GoFan Sign Up'
  },
  changePassword: {
    id: 'changePassword',
    name: 'Change Password',
    icon: 'changePassword',
    path: '/change-password',
    getPath: () => '/change-password',
    pageTitle: 'GoFan Change Password'
  },
  createPassword: {
    id: 'createPassword',
    name: 'Create Password',
    icon: 'createPassword',
    path: '/create-password',
    getPath: () => '/create-password',
    pageTitle: 'GoFan Create Password'
  },
  createPasswordSuccess: {
    id: 'createPasswordSuccess',
    name: 'Create Password Success',
    icon: 'createPasswordSuccess',
    path: '/create-password-success',
    getPath: () => '/create-password-success',
    pageTitle: 'GoFan Create Password Success'
  },
  'forgot-password': {
    id: 'forgot-password',
    name: 'Forgot Password',
    icon: 'forgot-password',
    path: '/forgot-password',
    getPath: () => '/forgot-password',
    pageTitle: 'GoFan Forgot Password'
  },
  myTickets: {
    id: 'myTickets',
    name: 'myTickets',
    icon: 'myTickets',
    pageTitle: 'My Tickets',
    path: '/my-tickets',
    getPath: () => '/my-tickets'
  },
  event: {
    id: 'events',
    name: 'events',
    icon: 'events',
    path: '/event/:id',
    pageTitle: 'Select Options',
    getPath: (eventId: string) => `/event/${eventId}`,
    hideMobileFooter: true
  },
  'seat-selection': {
    id: 'seat-selection',
    name: 'Seat Selection',
    icon: 'seat-selection',
    path: '/event/seat-selection/:id',
    pageTitle: 'Seat Selection',
    getPath: (eventId: string) => `/event/seat-selection/${eventId}`,
    hideMobileFooter: true
  },
  'season-seat-selection': {
    id: 'season-seat-selection',
    name: 'Season Seat Selection',
    icon: 'seat-selection',
    path: '/season/seat-selection/:id',
    pageTitle: 'Season Seat Selection',
    getPath: (eventId: string) => `/season/seat-selection/${eventId}`,
    hideMobileFooter: true
  },
  'hidden-event': {
    id: 'hidden-events',
    name: 'Hidden Events',
    icon: 'events',
    path: '/p/:encodedString',
    pageTitle: 'Select Options',
    getPath: (encodedString: string) => `/p/${encodedString}`,
    hideMobileFooter: true
  },
  season: {
    id: 'seasons',
    name: 'seasons',
    icon: 'seasons',
    path: '/season/:id',
    pageTitle: 'Select Options',
    getPath: (id: string) => `/season/${id}`,
    hideMobileFooter: true
  },
  'my-schools': {
    id: 'my-schools',
    name: 'My Schools',
    icon: 'my-schools',
    path: '/my-schools',
    pageTitle: 'GoFan: My Schools',
    getPath: () => '/my-schools'
  },
  'event-sad-paths': {
    id: 'event-sad-paths',
    name: 'Event Sad Paths',
    icon: '',
    path: '/event-sad-paths',
    pageTitle: 'EventSadPaths',
    getPath: () => '/event-sad-paths'
  },
  'sign-up-success': {
    id: 'sign-up-success',
    name: 'Sign Up Success',
    icon: '',
    path: '/sign-up-success',
    pageTitle: 'Sign Up Success',
    getPath: () => '/sign-up-success'
  },
  'check-email': {
    id: 'check-email',
    name: 'Check Email',
    icon: '',
    path: '/check-email',
    pageTitle: 'Check Email',
    getPath: () => '/check-email'
  },
  'best-practice': {
    id: 'best-practice',
    name: 'Best practice',
    icon: '',
    path: '/best-practice',
    pageTitle: 'Best practice',
    getPath: () => '/best-practice'
  },
  'web-view': {
    id: 'web-view',
    name: 'Web View',
    icon: '',
    path: '/web-view',
    pageTitle: 'Web View',
    getPath: () => '/web-view'
  },
  orders: {
    id: 'orders',
    name: 'Orders',
    icon: '',
    path: '/orders',
    pageTitle: 'Orders',
    getPath: () => '/orders'
  },
  orderDetails: {
    id: 'order-details',
    name: 'Order Details',
    icon: '',
    path: '/order-details/:access-token',
    pageTitle: 'Order Details',
    getPath: (accessToken: string) => `/order-details/${accessToken}`
  },
  maintenance: {
    id: 'maintenance',
    name: 'Maintenance',
    icon: '',
    path: '/maintenance',
    getPath: () => '/maintenance',
    pageTitle: 'Maintenance'
  },
  error: {
    id: 'error',
    name: 'Error',
    icon: '',
    path: '/error',
    getPath: () => '/error',
    pageTitle: 'Error'
  },
  info: {
    id: 'info',
    name: 'Info',
    icon: '',
    path: '/info',
    getPath: () => '/info',
    pageTitle: 'Info'
  },
  tickets: {
    id: 'tickets',
    name: 'Tickets',
    icon: '',
    path: '/tickets/:eventId',
    pageTitle: 'My tickets',
    getPath: (eventId: string, queryString: string = '') => `/tickets/${eventId}${queryString}`,
    hideMobileFooter: true
  },
  donations: {
    id: 'donations',
    name: 'Donations',
    icon: '',
    path: '/donations/:eventId',
    pageTitle: 'My donations',
    getPath: (eventId: string, queryString: string = '') => `/donations/${eventId}${queryString}`,
    hideMobileFooter: true
  },
  mobilePasses: {
    id: 'mobile-passes',
    name: 'Mobile Passes',
    icon: '',
    path: '/mobile-pass/:eventId',
    pageTitle: 'Mobile Passes',
    getPath: (eventId: string, queryString: string = '') => `/mobile-pass/${eventId}${queryString}`,
    hideMobileFooter: true
  },
  transferTicket: {
    id: 'transfer-ticket',
    name: 'Transfer Ticket',
    icon: '',
    path: '/transfer/tickets/:access-token',
    pageTitle: 'My Tickets',
    getPath: (accessToken: string) => `/tickets/${accessToken}`,
    hideMobileFooter: true
  },
  transferDonation: {
    id: 'transfer-donation',
    name: 'Transfer Donation',
    icon: '',
    path: '/transfer/donations/:access-token',
    pageTitle: 'My Donations',
    getPath: (accessToken: string) => `/donations/${accessToken}`,
    hideMobileFooter: true
  },
  transferMobilePass: {
    id: 'transfer-mobile-pass',
    name: 'Transfer Mobile Pass',
    icon: '',
    path: '/transfer/mobile-pass/:access-token',
    pageTitle: 'Accept pass',
    getPath: (accessToken: string) => `/tickets/${accessToken}`,
    hideMobileFooter: true
  },
  redemption: {
    id: 'redemption',
    name: 'Redemption',
    icon: 'redemption',
    path: '/redemption',
    getPath: () => '/redemption',
    pageTitle: 'My tickets',
    hideMobileFooter: true,
    hideCookiesBanner: true
  },
  passUnavailable: {
    id: 'pass-unavailable',
    name: 'Pass Unavailable',
    icon: '',
    path: '/pass-unavailable',
    getPath: () => '/pass-unavailable',
    pageTitle: 'Pass Unavailable'
  },
  'season-renewal': {
    id: 'season-renewal',
    name: 'Season Renewal',
    icon: '',
    path: '/renewal/:id',
    getPath: (seasonId: string) => `/renewal/${seasonId}`,
    pageTitle: 'Season Tickets Renewal'
  },
  'legacy-season-renewal-decline': {
    id: 'legacy-season-renewal-decline',
    name: 'Season Renewal Decline',
    icon: '',
    path: '/decline-season-ticket-renewal',
    getPath: () => `/decline-season-ticket-renewal`,
    pageTitle: 'Decline Season Tickets Renewal'
  },
  scan: {
    id: 'scan',
    name: 'Scan',
    icon: '',
    path: '/scan',
    getPath: () => '/scan',
    pageTitle: 'Scan QR Code'
  },
  browserTransferTicket: {
    id: 'browser-transfer-ticket',
    name: 'Transfer Ticket',
    icon: '',
    path: '/browser/transfer/tickets/:access-token',
    pageTitle: 'My Tickets',
    getPath: (accessToken: string) => `/browser/transfer/tickets/${accessToken}`,
    hideMobileFooter: true
  },
  downloadTickets: {
    id: 'download-tickets',
    name: 'Download Tickets',
    icon: '',
    path: '/download-tickets',
    pageTitle: 'Download Your Tickets',
    getPath: (type: string, data: string) => `/download-tickets?${type}=${data}`,
    hideMobileFooter: true
  },
  profile: {
    id: 'profile',
    name: 'Profile',
    icon: '',
    path: '/profile',
    pageTitle: 'Profile',
    getPath: () => `/profile`
  },
  profileNotifications: {
    id: 'notifications',
    name: 'Notifications',
    icon: '',
    path: '/profile/notifications',
    pageTitle: 'Profile',
    getPath: () => `/profile/notifications`,
    hideMobileFooter: true
  },
  profileMyInfo: {
    id: 'my-info',
    name: 'My Info',
    icon: '',
    path: '/profile/my-info',
    pageTitle: 'Profile',
    getPath: () => `/profile/my-info`,
    hideMobileFooter: true
  },
  allSchools: {
    id: 'allSchools',
    name: 'All Schools',
    icon: '',
    path: '/all-schools',
    getPath: () => '/all-schools',
    pageTitle: 'Schools'
  },
  profileOrderHistory: {
    id: 'order-history',
    name: 'Order history',
    icon: '',
    path: '/profile/order-history',
    pageTitle: 'Profile',
    getPath: () => `/profile/order-history`,
    hideMobileFooter: true
  },
  updateApp: {
    id: 'update-app',
    name: 'Update App',
    icon: '',
    path: '/update-app',
    getPath: () => '/update-app',
    pageTitle: 'Update App'
  },
  fandom: {
    id: 'fandom',
    name: 'Fandom',
    icon: '',
    path: '/profile/fandom',
    pageTitle: 'Profile',
    getPath: () => `/profile/fandom`,
    hideMobileFooter: false
  }
};

type NamesToPaths = {
  [n: string]: string;
};

export const namesToPaths: NamesToPaths = {};
export const pathsToPageConfig: PageConfig = {};

Object.values(GOFAN_APP_PAGES).forEach(route => {
  namesToPaths[route.name] = route.path;
  pathsToPageConfig[route.path] = route;
});

// Sign in
export const EMAIL_ALREADY_IN_USE = 'auth/email-already-in-use';
export const EMAIL_ALREADY_IN_USE_MESSAGE = 'The email address you have entered is already in use.';

export const EMAIL_NOT_IN_USE = 'auth/email-not-in-use';
export const EMAIL_NOT_IN_USE_MESSAGE = "The email address you've entered does not match any account.";

export const INVALID_EMAIL = 'auth/invalid-email';
export const INVALID_EMAIL_MESSAGE = 'Enter a valid email address.';

export const USER_DISABLED = 'auth/user-disabled';
export const ACCOUNT_DISABLED_MESSAGE = 'The account is disabled';

export const USER_NOT_FOUND = 'auth/user-not-found';
export const USER_NOT_FOUND_MESSAGE =
  'We do not recognize your email address and password combination. Please try again or Sign Up below.';
export const USER_NOT_FOUND_MESSAGE_GUEST =
  'We do not recognize your email address and password combination. Please try again.';

export const WRONG_PASSWORD = 'auth/wrong-password';
export const WRONG_PASSWORD_MESSAGE = 'Your password is incorrect.';

export const TOO_MANY_REQUEST_ATTEMPT = 'auth/too-many-requests';
export const TOO_MANY_REQUEST_ATTEMPT_MESSAGE =
  'Too many login attempts. Please remember that GoFan passwords are case sensitive.' +
  '\n\nIf you forgot your password, you may reset your password by following the forgot password below.';

export const OPERATION_NOT_ALLOWED = 'auth/operation-not-allowed';
export const UNKNOWN = 'auth/unknown';
export const CREATE_GF_USER_ERROR = 'CREATE_GF_USER_ERROR';
export const SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG';

export const MAPPING_FIREBASE_CODE_AND_ERROR_MESSAGES: { [firebaseCode: string]: string } = {
  [WRONG_PASSWORD]: USER_NOT_FOUND_MESSAGE,
  [USER_NOT_FOUND]: USER_NOT_FOUND_MESSAGE,
  [EMAIL_ALREADY_IN_USE]: EMAIL_ALREADY_IN_USE_MESSAGE,
  [EMAIL_NOT_IN_USE]: EMAIL_NOT_IN_USE_MESSAGE,
  [INVALID_EMAIL]: INVALID_EMAIL_MESSAGE,
  [OPERATION_NOT_ALLOWED]: ACCOUNT_DISABLED_MESSAGE,
  [USER_DISABLED]: ACCOUNT_DISABLED_MESSAGE,
  [TOO_MANY_REQUEST_ATTEMPT]: TOO_MANY_REQUEST_ATTEMPT_MESSAGE,
  [UNKNOWN]: TOO_MANY_REQUEST_ATTEMPT_MESSAGE,
  [CREATE_GF_USER_ERROR]: 'Cannot create GoFan user with this email. Please try again.',
  [SOMETHING_WENT_WRONG]: 'Something went wrong. Please try again.'
};

export const GF_PROD_BOXOFFICE = 'Box Office';
export const BOXOFFICE = 'BOXOFFICE';

export const EMAIL_FORMAT_PATTERN = new RegExp(
  '^[_A-Za-z0-9-]+[+_A-Za-z0-9-]*(\\.[+_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})(\\-\\-([A-Za-z0-9]+))?$'
);

// Ref: https://regexpattern.com/phone-number/
// https://regexr.com/
export const US_PHONE_NUMBER_PATTERN = new RegExp(/^(\(?[0-9]{3}\)? ?|[0-9]{3}[.-]? ?)[0-9]{3}[.-]? ?[0-9]{4}$/);
export const NAME_PATTERN = new RegExp(/^[a-z ,.'-]+$/i);
export const ZIP_CODE_PATTERN = new RegExp(/^[0-9]{5}$/);
export const DEFAULT_BlACK_COLOR = '#000000';
export const INVALID_COLOR = '#e8e8e8';
export const DEFAULT_FUNDRAISER_PROGRESS_BAR_COLOR = '323232';
export const TRACKING_PAGE_VIEW_NAMES = {
  HOME: 'Home',
  EVENT_LIST: 'Event List',
  EVENT_DETAILS: 'Event Details',
  SIGN_IN: 'Sign In',
  SIGN_UP: 'Sign Up',
  FORGOT_PASSWORD: 'Forgot Password',
  MY_SCHOOLS: 'My Schools',
  MY_TICKETS: 'My Tickets',
  SEATING_CHART: 'Seating Chart',
  EVENT_TICKETS: 'Event Tickets',
  CART: 'Cart',
  CHANGE_PASSWORD: 'Change Password',
  ORDER_DETAILS: 'Order Details',
  REDEMPTION: 'Redemption',
  FAN_SCAN: 'Fan scan',
  ALL_SCHOOLS: 'All schools'
};

export const INDUSTRIAL_CODES = {
  SCHOOL_BOARD: 'School Board',
  STATE_ASSOCIATION: 'State Association',
  CRICKET_LEAGUE: 'Cricket League',
  DAY_CARE: 'Day Care',
  ELEMENTARY_MEMBER_SCHOOL: 'Elementary Member School',
  ELEMENTARY_SCHOOL: 'Elementary School',
  ELEMENTARY_SCHOOL_BOARD: 'Elementary School Board',
  FIRE_STATION: 'Fire Station',
  HIGH_SCHOOL: 'High School',
  MEMBER_SCHOOL: 'Member School',
  ROAD_RACE: 'Road Race',
  SOCCER_LEAGUE: 'Soccer League',
  SOFTBALL_LEAGUE: 'Softball League',
  SPECIAL_EVENT: 'Special Event'
};

export const GOFAN_SCHOOL_TYPES = {
  AGGREGATOR: {
    SCHOOL_DISTRICT: 'School District',
    ATHLETIC_CONFERENCE: 'Athletic Conference',
    STATE_ASSOCIATION: 'State Association',
    COMPANY: 'Company',
    SECTION: 'Section'
  },
  SINGLE: {
    HIGH_SCHOOL: 'High School',
    MIDDLE_SCHOOL: 'Middle School',
    PERFORMING_ARTS: 'Performing Arts',
    SUBACCOUNT: 'Subaccount',
    BOOSTER_CLUB: 'Booster Club',
    ELEMENTARY_SCHOOL: 'Elementary School',
    ATHLETIC_DEPARTMENT: 'Athletic Department',
    COLLEGE: 'College',
    SPECIAL_EVENT: 'Special Event'
  }
};

export const SORTED_LEVELS = ['Varsity', 'Junior Varsity', 'Sophomore', 'Froshmore', 'Freshman', 'Middle School'];
export const GENDERS = {
  BOYS: 'Boys',
  GIRLS: 'Girls',
  COED: 'Coed'
};

export const LOCATION = {
  HOME: 'Home',
  AWAY: 'Away'
};

export const PLAYOFFS_VALUES = ['true', 'false'];

export const FILTER_BATCH = 'batch';
export const FILTER_ACTIVITY = 'activity';
export const FILTER_LEVEL = 'level';
export const FILTER_GENDER = 'gender';
export const FILTER_HOMEAWAY = 'location';
export const FILTER_PLAYOFFS = 'playoffs';
export const FILTER_SCHOOLS = 'school';
export const FILTER_OTHER = 'other';

export const FILTER_OPTIONS = [
  FILTER_ACTIVITY,
  FILTER_LEVEL,
  FILTER_GENDER,
  FILTER_HOMEAWAY,
  FILTER_PLAYOFFS,
  FILTER_SCHOOLS,
  FILTER_OTHER
];
export const FILTER_OPTIONS_NO_LOCATION = [
  FILTER_ACTIVITY,
  FILTER_LEVEL,
  FILTER_GENDER,
  FILTER_PLAYOFFS,
  FILTER_SCHOOLS
];

// event type
export const ATHLETIC = 'Athletic';
export const OTHER = 'Other';
export const MOBILE_PASS = 'Mobile Pass';
export const PERFORMING_ART = 'Performing Arts';
export const REGISTRATION = 'Registration';
export const SCHOOL_DANCE = 'School Dance';
export const PROM = 'Prom';
export const NONLEVEL_GENDER_EVENT_TYPE = [PERFORMING_ART, REGISTRATION, SCHOOL_DANCE, PROM];

export const SERVICE_FEES_NOTI =
  'GoFan collects a service fee to support and grow a platform for schools. This covers the cost of the technology and credit card processing.';
export const PHONE_IS_TICKET_NOTI =
  'Tickets for this event must be presented using your mobile device, via the GoFan app or the gofan.co mobile site. You will not be able to print tickets for this event. Screenshots are not accepted for entry.';
export const ORDER_LIMIT = 'ORDER_LIMIT';
export const TICKET_LIMIT = 'TICKET_LIMIT';
export const EVENT_LIMIT = 'EVENT_LIMIT';
export const ORDER_LIMIT_PER_TICKET = 'ORDER_LIMIT_PER_TICKET';

export const UNLIMITED_QUANTITY = 9999999;

export const DEFAULT_SEGMENT_CONFIG = {
  size: 20,
  timeout: 500
};

export const AUTO_TRACK_PAGES = [
  {
    path: GOFAN_APP_PAGES.home.path,
    name: GOFAN_APP_PAGES.home.name
  },
  {
    path: GOFAN_APP_PAGES.search.path,
    name: GOFAN_APP_PAGES.home.name
  }
];

export const TRACKING_EVENTS = {
  FILTER: 'Filter',
  SEARCH: 'Search',
  SEARCH_RESULT_SELECTED: 'Search result selected',
  FAVORITE: 'Favorite',
  UN_FAVORITE: 'Unfavorite',
  VIEW_EVENT: 'View Event',
  REFRESH_EVENT_LIST: 'Refresh event list',
  USE_TICKETS: 'Use tickets',
  PRINT_TICKETS: 'Print tickets',
  STORE_REVIEW_PROMPT: 'Store review prompt',
  VIEW_DISTRICT_SCHOOL_LIST: 'View district school list',
  TEAM_SITE_PROMOTION: 'Team site promotion',
  SPONSORSHIP: 'Sponsorship Clicked'
};

export const SEARCH_RESULT_CATEGORIES = {
  MY_FAVORITES: 'My favorites',
  RECENTLY_VIEWED: 'Recently viewed',
  SEARCH_RESULTS: 'Search results'
};

export const STRIPE_PAYMENT_TYPES = {
  GOOGLE_PAY: 'Google Pay',
  APPLE_PAY: 'Apple Pay'
};

export const GUEST_TYPES = {
  GOOGLE_PAY: 'GooglePay',
  APPLE_PAY: 'ApplePay',
  GUEST_CHECKOUT: 'GuestCheckout'
};

export const TICKET_STATE = {
  AVAILABLE: 'Available',
  USED: 'Used',
  TRANSFERRED: 'Transferred',
  REFUNDED: 'Refunded'
};

export const SELECT_MODE = {
  SELECT: 'Select all',
  DE_SELECT: 'Deselect all'
};

export const USE_TRANSFER_MODES = {
  USE: 'use',
  TRANSFER: 'transfer'
};

export const TICKET_ACTION_TITLES = {
  CANCEL_TRANSFER: 'Cancel transfer',
  TRANSFER_PASS: 'Transfer pass',
  CANNOT_TRANSFERRED: 'Cannot be transferred'
};

export const TICKET_DETAIL_TITLES = {
  AVAILABLE_NOW: 'Available Now',
  AVAILABLE_LATER: 'Available Later'
};

export const PAYMENT_ERRORS = {
  EVENT_SOLD_OUT: 'Event is sold out',
  EVENT_OVER_LIMIT: 'Event capacity less than requested number of tickets',
  EVENT_OVER_LIMIT_PER_ORDER: 'Invalid total number of tickets',
  EVENT_IN_THE_PAST: 'Cannot create order',
  PRODUCT_SOLD_OUT: 'Product is sold out',
  PRODUCT_OVER_LIMIT: 'Product capacity less than requested number of tickets',
  SCHOOL_OVER_LIMIT: 'School capacity less than requested number of tickets',
  PRODUCT_REQUIRES_PROMO: 'Ticket requires a Promo',
  UNAVAILABLE_SALES: 'UNAVAILABLE_SALES',
  INVALID_TICKETS:
    'There was an issue booking the following seats. We have removed the seats from your cart. Please try your order again.',
  TICKET_LIMIT_PER_ORDER: 'Requested ticket quantity exceeds product limit per order',
  TICKET_SALE_TIME_WINDOW: 'Tickets cannot be purchased',
  INVALID_FIELD_INFO: 'Invalid Field info',
  INVALID_FORM_ID: 'Trying to use an invalid form id'
};

export const SEGMENTS_TRACKING_EVENTS = {
  ORDER_COMPLETED: 'Order Completed',
  PRODUCT_ORDERED: 'Product Ordered',
  GTAG_CONVERSION: 'conversion',
  PIXEL_COMPLETE_REGISTRATION: 'CompleteRegistration',
  PIXEL_PURCHASE: 'Purchase',
  PIXEL_INITIATE_CHECKOUT: 'InitiateCheckout',
  CODE_ENTERED: 'Code Entered',
  CODE_APPLIED: 'Code Applied',
  CODE_DENIED: 'Code Denied',
  CODE_REMOVED: 'Code Removed',
  PRODUCT_ADDED: 'Product Added',
  PRODUCT_REMOVED: 'Product Removed',
  CART_VIEWED: 'Cart Viewed',
  CHECKOUT_STARTED: 'Checkout Started',
  PAYMENT_INFO_ENTERED: 'Payment Info Entered',
  TEAM_FAVORITED: 'Team Favorited'
};

export const GA4_TRACKING_EVENTS = {
  ORDER_COMPLETED: 'order_completed',
  PRODUCT_ORDERED: 'product_ordered',
  CODE_ENTERED: 'code_entered',
  CODE_APPLIED: 'code_applied',
  CODE_DENIED: 'code_denied',
  CODE_REMOVED: 'code_removed',
  PRODUCT_ADDED: 'product_added',
  PRODUCT_REMOVED: 'product_removed',
  CART_VIEWED: 'viewed_cart',
  CHECKOUT_STARTED: 'checkout_started',
  PAYMENT_INFO_ENTERED: 'payment_info_entered',
  PRINT_TICKETS: 'print_tickets',
  USE_TICKETS: 'use_tickets',
  FILTER: 'filter',
  SEARCH: 'search',
  VIEW_DISTRICT_SCHOOL_LIST: 'viewed_district_school_list',
  TEAM_SITE_PROMOTION: 'team_site_promotion',
  SPONSORSHIP: 'sponsorship_clicked',
  TEAM_FAVORITED: 'team_favorited'
};

export const INPUT_METHOD = {
  CLICK: 'click',
  ENTER: 'enter',
  EMPTY: '',
  SYSTEM_REMOVE: 'system_remove'
};

export const REFRESH_METHOD = {
  REFRESH: 'refresh',
  NEW_EVENTS: 'check for new events'
};

export const RENEWAL = {
  NEW: 'new',
  RENEWAL: 'renewal'
};

export const PAYMENT_TYPE = {
  APPLE_PAY: 'APPLE_PAY',
  GOOGLE_PAY: 'GOOGLE_PAY',
  AUTHORIZED: 'AUTHORIZED',
  STRIPE: 'STRIPE',
  GUEST_CHECKOUT: 'GUEST_CHECKOUT',
  LINK: 'LINK',
  CARD: 'CARD',
  CASH_APP: 'CASHAPP',
  PAYPAL: 'PAYPAL',
  AMAZON_PAY: 'AMAZON_PAY',
  ALI_PAY: 'ALI_PAY',
  PAYMENT_SHEET: 'PAYMENT_SHEET'
};

export * from './config';
export * from './activity-icons';

/*
 * We have 3 options for native redirected paths:
 *  - absolute destinations that will open in an external browser
 *  - relative destinations like ("/" or "/my-tickets")
 *  - parameterized relative destinations using a provided getDestination
 *    funcion that accepts react-navigation route params
 *
 * Note on absolute URLS: We can't simply have deep linking ignore these routes.
 * Android doesn't provide a way to explicitly ignore routes. The user has to bounce
 * through the app so the app can open the external url.
 */
export const nativeRedirects = [
  { name: 'Legacy About', path: '/about-us', destination: 'https://get.gofan.co/company/about' },
  { name: 'Legacy Contact', path: '/contact-us', destination: 'https://fansupport.gofan.co/' },
  { name: 'Legacy FAQs', path: '/faqs', destination: 'https://fansupport.gofan.co/' },
  { name: 'Legacy Send Note', path: '/send-us-a-note', destination: 'https://fansupport.gofan.co/' },
  { name: 'Legacy Send Note Success', path: '/send-note-success', destination: 'https://fansupport.gofan.co/' },
  { name: 'Legacy Site Map', path: '/site-map', destination: '/' },
  { name: 'Legacy Search', path: '/search', destination: '/' },
  { name: 'Legacy Welcome', path: '/welcome', destination: '/' },
  { name: 'Legacy My Offers', path: '/my-offers', destination: '/my-tickets' },
  { name: 'Legacy View Season', path: '/view-season/:id', destination: '/my-tickets' },
  { name: 'Legacy Validate', path: '/validate', destination: '/my-tickets' },
  { name: 'Legacy Transfer Pass', path: '/transfer-pass', destination: '/my-tickets' },
  {
    name: 'Legacy Event Details',
    path: '/app/events/:id',
    destination: '/event/:id',
    getDestination: (params: { [key: string]: string }) => {
      return { key: 'event', params };
    }
  },
  {
    name: 'Legacy Season Details',
    path: '/app/events/season/:id',
    destination: '/season/:id',
    getDestination: (params: { [key: string]: string }) => {
      return { key: 'season', params };
    }
  },
  {
    name: 'Legacy Box Office Receipt',
    path: '/order-receipt/:id',
    destination: '/order-details/:id',
    getDestination: (params: { [key: string]: string }) => {
      return { key: 'orderDetails', params };
    }
  },
  {
    name: 'Legacy Ticket details',
    path: '/app/tickets/:id',
    destination: '/tickets/:eventId',
    getDestination: (params: { [key: string]: string }) => {
      if (params.type === 'mobilepass') {
        return { key: 'mobilePasses', params };
      }
      return { key: 'tickets', params };
    }
  },
  {
    name: 'Legacy Accept Transfer Ticket',
    path: '/transfer',
    destination: '/transfer/tickets/:access-token',
    getDestination: (params: { [key: string]: string }) => {
      return { key: 'transferTicket', params };
    }
  },
  {
    name: 'Legacy Accept Transfer Mobile Pass',
    path: '/transfer/mobilepass/:accessToken',
    destination: '/transfer/mobile-pass/:access-token',
    getDestination: (params: { [key: string]: string }) => {
      return { key: 'transferMobilePass', params };
    }
  },
  {
    name: 'School Short Name',
    path: '/:id',
    destination: '/app/school/:id',
    getDestination: (params: { [key: string]: string }) => {
      return { key: 'school', params };
    }
  }
];

export const EVENT_TYPES = {
  ATHLETIC,
  PERFORMING_ART,
  REGISTRATION,
  SCHOOL_DANCE,
  MOBILE_PASS,
  PROM,
  OTHER
};

export const BROWSER_ONLY_URLS = ['https://gofan.co'];
export const PLAY_ON_SUPPORT_URL = 'https://www.playonsports.com/';
export const GOFAN_URL = 'https://gofan.playonsports.com/overview';
export const NFHS_URL = 'https://nfhsnetwork.playonsports.com/overview';
export const R_SCHOOL_TODAY_URL = 'https://playonsports.com/products/rschooltoday';

export const SORT_EVENTS = {
  ASCENDING: 'startDateTime,asc',
  DESCENDING: 'startDateTime,des'
};

export const DEFAULT_PAGING_SIZE = 200;
export const DEFAULT_SHOW_MORE_SIZE = 50;
export const DEFAULT_SHOW_PRINT_TICKET_MODAL = 150;

export const PROMOTION_ERRORS = {
  CODE_IS_NOT_VALID: 'data invalid',
  CODE_IS_NOT_EXISTED: 'promo code is not found for request',
  CODE_EXCEED_USAGE_LIMIT: 'promo has exceeded usage limit',
  CODE_IS_NOT_AVAILABLE: 'promo code will be available on',
  CODE_IS_EXPIRED: 'we’re sorry, the promo code has expired'
};

export const PROMOTION_ERROR_MESSAGES = {
  CODE_IS_NOT_VALID: `Promo code is invalid.`,
  CODE_IS_NOT_EXISTED: `Promo code is invalid.`,
  CODE_EXCEED_USAGE_LIMIT: `We're sorry, the promo code has exceeded its maximum number of uses.`,
  CODE_IS_NOT_AVAILABLE: 'Promo code will be available on ${date}.',
  CODE_IS_EXPIRED: `We’re sorry, the promo code has expired.`,
  CODE_IS_ADDED: `Promo code has already been applied.`,
  CODE_APPILED_ON_SOLD_OUT_TICKET: `Access codes can’t be applied to sold out tickets.`,
  CODE_ALREADY_APPLIED: `Promo code has already been applied.`,
  CODE_ONLY_ONE_PER_TICKET: `You can only apply one code per ticket.`,
  UNKNOWN_ERROR: `We're sorry, something went wrong.`
};

export const MAX_TICKETS_PER_PROMO_PER_ORDER = 150;

export enum TicketState {
  'AVAILABLE',
  'USED',
  'TRANSFERRED',
  'REFUNDED',
  'TRANSFER_UNAVAILABLE'
}

export enum TicketAction {
  'Use',
  'Transfer',
  'Edit'
}

export type USE_TRANSFER_MODE = 'use' | 'transfer';

export enum CheckoutStage {
  INITIAL = 'Buy now',
  PROCESSING = 'Processing ...',
  SUCCESS = 'Success! Getting your order now ...',
  ERROR = 'Error'
}

export enum AccessCodeType {
  NONE,
  LOCKED,
  UNLOCKED
}

export const TICKET_WIDTH_DESKTOP = 512;
export const TICKET_PADDING_DESKTOP = 32;
export const TICKET_WIDTH_MOBILE = 320;
export const MAX_WINDOW_WIDTH = 1056;
export const UNENCRYPTED_FIELDS = ['phone-number', 'last-name', 'first-name'];

export const EMAIL_SOURCES: {
  [key: string]: string;
} = {
  order: 'order confirmation',
  scan: 'Scan email',
  reminder: 'event reminder email'
};

export const EVENT_DETAILS_LABEL = {
  TICKET: 'Event details',
  MOBILEPASS: 'Pass details',
  DONATION: 'Donation details',
  DONATION_ONGOING: 'Donation details',
  SEASON: 'Event details',
  ITEM: 'Event details',
  DEFAULT: 'Event details'
};

export const USER_STATUS = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
  DELETED: 'DELETED',
  PENDING: 'PENDING'
};

export const NEW_RELIC_ERROR_GROUPS = {
  // Initialization & Setup
  AppInitialization: 'AppInitialization',
  DeviceSettings: 'DeviceSettings',

  // Navigation & UX
  Navigation: 'Navigation Error',
  UserActions: 'UserActions',

  // Data Handling (Parsing, Validation, etc.)
  DataIssues: 'DataIssues',
  Cache: 'Cache',

  // Transactions & Codes
  PaymentAndOrders: 'PaymentAndOrders',
  Transfers: 'Transfers',
  AccessAndPromoCodes: 'AccessAndPromoCodes',
  Cart: 'Cart',

  // Server & API Communication
  APIRequests: 'APIRequests',

  // Third-Party Services
  Firebase: 'Firebase',
  GeoLocation: 'GeoLocation',
  LaunchDarkly: 'LaunchDarkly',
  Recaptcha: 'Recaptcha',
  SeatsIo: 'SeatsIo',
  Segment: 'Segment',
  Stripe: 'Stripe'
};

export const onloadScript = `
  <script>
  function reportHeight () {
    const height = document.body.scrollHeight;
    window.ReactNativeWebView.postMessage(height);
  }
  document.addEventListener("DOMContentLoaded", function(event) {
    Promise.all(Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; }))).then(() => {
    setTimeout(reportHeight, 500);
    });
  });
  </script>
`;

export const NON_DIGIT_REGEX = /[^0-9]+/gi;

export const PAYMENT_METHOD = {
  APPLE_PAY: 'APPLE',
  GOOGLE_PAY: 'GOOGLE',
  LINK: 'LINK',
  CASH_APP: 'cashapp',
  PAYPAL: 'paypal'
};

export const PAYMENT_METHOD_LABEL = {
  CASH_APP: 'Cash App',
  PAYPAL: 'PayPal',
  FREE: 'Free',
  STRIPE: 'Stripe',
  APPLE_PAY: 'Apple Pay',
  GOOGLE_PAY: 'Google Pay',
  LINK: 'Link',
  ALI_PAY: 'Alipay'
};

export const COOKIE_ACKNOWLEDGED_KEY = 'cookies_acknowledged';

export const DEFAULT_PAYMENT_VALIDATION = {
  title: '',
  description: '',
  onConfirm: () => {}
};

export const DEFAULT_MERCHANT_NAME = 'GOFAN';
